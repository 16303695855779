import './App.css';
import Home from './Components/Home';
import { Route, Routes } from "react-router-dom";
import AboutUs from './Components/AboutUs';
import Terms from './Components/Terms';
import Policy from './Components/Policy';
import ContactUs from './Components/ContactUs';
import Refund from './Components/Refund';
import ShipingPolicy from './Components/ShipingPolicy';
import FAQ from './Components/FAQ';
import Down from './Down';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Down />} />
      {/* <Route path='/' element={<Home />} />
      <Route path='/aboutus' element={<AboutUs />} />
      <Route path='/terms' element={<Terms />} />
      <Route path='/policy' element={<Policy />} />
      <Route path='/contact' element={<ContactUs />} />
      <Route path='/refund' element={<Refund />} />
      <Route path='/shipingpolicy' element={<ShipingPolicy />} />
      <Route path='/FAQ' element={<FAQ />} /> */}
    </Routes>
  );
}

export default App;
